<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Edit article
			</div>
		</div>

		<div>
			<article-edit
				:articleId="getArticleId"
				:cancelRoute="getCancelRoute"
				:isCreate="false"
			/>
		</div>
	</div>
</template>

<script>
	import ArticleEdit  from '@/components/knowledgebase/edit/ArticleEdit';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Edit category'
		},
		components: {
			ArticleEdit
		},
		computed: {
			getCancelRoute () {
				return '/admin/knowledgebase/articles';
			},
			getArticleId () {
				return this.$route.params.articleId;
			}
		},
		created () {}
	};
</script>
